.caseStudies {
  background: var(--color-dark);
  margin: 1rem auto;
  border-radius: 45px;
  color: #fff;
  padding: 2rem 0 1.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.case-studies-item {
  display: inline-block;
}

.caseStudies p {
  padding: 0 1rem;
}

.caseStudies a {
  color: var(--color-highlight);
  display: block;
  text-decoration: none;
  margin: 1rem;
}

.caseStudies a svg {
  font-size: 26px;
}

.caseStudies a:hover {
  color: var(--color-grey);
}

@media (max-width: 768px) {
  .caseStudies {
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 0;
    margin: 0;
  }

  .case-studies-item {
    display: inline-block;
    min-width: 96%;
    border: none;
    background: var(--color-dark);
    border-radius: 45px;
    color: #fff;
    padding: 2rem 1rem 1.5rem;
    margin: 1rem;
  }

  .caseStudies p {
    margin-top: 2rem;
  }
}

.caseStudies div {
  border-right: 1px solid #fff;
}

.caseStudies div:last-child {
  border: none;
}
