.cardTeam{
  background: #ffffff;
  border: 1px solid #191a23;
  box-shadow: 0px 5px 0px #191a23;
  border-radius: 45px;
  padding: 2rem 1rem 0rem;
  margin: 0 0.3rem 1.5rem;
  min-height: 250px;
  max-width: 390px;
}

.cardTeamHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.cardTeamHeader img {
  width: 70px;
}

.cardTeamHeader span {
  display: block;
  font-weight: 700;
}

.cardTeamHeader a {
  align-self: flex-start;
  align-items: end;
}

.cardTeamHeader a svg {
  font-size: 20px;
  color: #000;
  background: var(--color-highlight);
}

.cardTeamHeader a svg:hover {
  background: #ffffff;
  transform: scale(1.1);
}
.cardTeam p {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  padding: 0 0.5rem;
}

@media (max-width: 768px) {
  .cardTeam {
    margin: 1.5rem auto;
    max-width: 460px;
  }
}
