.contact {
  width: 100%;
  background: #f3f3f3 url(../../assets/contact.png) no-repeat;
  background-size: contain;
  background-position: right center;
  border-radius: 45px;
  padding: 5rem 3rem;
  margin-bottom: 6rem;
}

.formGroup {
  margin: 1.5rem 0;
}

.formGroup input,
.formGroup textarea {
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 14px;
}

@media (max-width: 768px) {
  .contact {
    background: #f3f3f3;
    padding: 3rem 2rem;
    margin-bottom: 3rem;
  }
}
