.tscroll {
  visibility: hidden;
  display: none;
}
@media (max-width: 1024px) {
  .clients {
    justify-content: flex-start;
  }
  .tscroll {
    visibility: visible;
    display: flex;
    justify-content: center;
    font-size: 26px;
  }
}
